<template>
  <div id="portal-browse">
    <Portal to="action-bar">
      <keep-alive>
        <ItemActions
          v-if="!isLoading"
          item="portal"
          :columns.sync="columns"
          :group-by.sync="groupBy"
          :order.sync="sortBy.order"
          :filter-by.sync="filterBy"
          :active-view.sync="activeView"
          :criteria.sync="sortBy.criteria"
          @refresh="getPortals"
          @search="searchRow"
          @update="getPortals"
        />
      </keep-alive>
    </Portal>

    <div v-if="!isLoading" class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <PortalGrid
          v-if="activeView === 'GRID'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editPortal"
          @more="showPortalDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
          @info="copyLink"
        />

        <PortalList
          v-if="activeView === 'LIST'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editPortal"
          @more="showPortalDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
          @info="copyLink"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="eva-globe-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      v-if="!isLoading"
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
    />

    <PortalDetails
      v-if="isPortalDetailsVisible"
      v-model="isPortalDetailsVisible"
      :columns="columns"
      :portal="selectedPortal"
      @edit="editPortal"
      @delete="showDeleteConfirmation"
    />

    <ConfirmDeletePortal
      v-model="isDeleteConfirmationVisible"
      :portal="selectedPortal"
      @delete="updatePortal"
    />

    <ConfirmRestorePortal
      v-model="isRestoreConfirmationVisible"
      :portal="selectedPortal"
      @restore="updatePortal"
    />
  </div>
</template>

<script>
import { portal, user, workflow } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import PortalGrid from "./components/PortalGrid.vue";
import PortalList from "./components/PortalList.vue";
import PortalDetails from "./components/PortalDetails.vue";
import ConfirmDeletePortal from "./components/ConfirmDeletePortal.vue";
import ConfirmRestorePortal from "./components/ConfirmRestorePortal.vue";
import { copyToClipboard } from "quasar";

export default {
  name: "PortalsBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    ItemActions,
    ItemActionChips,
    PortalGrid,
    PortalList,
    PortalDetails,
    Pagination,
    ConfirmDeletePortal,
    ConfirmRestorePortal,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "workflow",
          label: "Workflow",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "status",
          label: "Status",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          options: [
            {
              id: this.$nano.id(),
              label: "Draft",
              value: "DRAFT",
            },
            {
              id: this.$nano.id(),
              label: "Published",
              value: "PUBLISHED",
            },
          ],
          isFilter: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE,
        },
      ],
      portals: [],
      portalData: [],
      activeView: "GRID",
      selectedPortal: {},
      isPortalDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "ASC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      userList: [],
      workflowList: [],
    };
  },

  computed: {
    items() {
      return this.portals.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((portal) => ({
          id: portal.id,
          name: portal.name,
          description: portal.description,
          workflow: portal.workflow,
          workflowId: portal.workflowId,
          superuser: portal.superUser ? portal.superUser.split(",") : [],
          icon: "mdi-web",
          infoIcon: "mdi-link-variant",
          status: "PUBLISHED",
          createdBy: portal.createdByEmail,
          createdAt: portal.createdAt,
          modifiedBy: portal.modifiedByEmail || portal.modifiedByEmail,
          modifiedAt: this.$day.format(portal.modifiedAt),
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.defaultView();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.mode, vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getPortals(),
      {
        immediate: true,
        deep: true,
      }
    );
  },

  created() {
    this.getUsers();
    this.getWorkflows();
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      if (Object.keys(options).length) {
        if (options[menu]) {
          let view = options[menu].find((item) => item.subMenu === subMenu);
          if (view) {
            this.activeView = view.view;
          }
        }
      }
    },

    findPortal(portalId) {
      const portals = [];
      this.items.forEach((item) => portals.push(...item.data));
      return portals.find((portal) => portal.id === portalId);
    },

    showPortalDetails(portalId) {
      this.selectedPortal = this.findPortal(portalId);
      this.isPortalDetailsVisible = true;
    },

    editPortal(portalId) {
      this.$router.push({
        name: "portal-builder",
        params: { id: String(portalId) },
      });
    },

    showDeleteConfirmation(portalId) {
      this.selectedPortal = this.findPortal(portalId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(portalId) {
      this.selectedPortal = this.findPortal(portalId);
      this.isRestoreConfirmationVisible = true;
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) {
        this.filterBy = [];
      }
      this.getPortals();
    },

    async copyLink(portalId) {
      let origin = location.origin;
      let linkText = "";
      if (
        origin === "https://app.ezofis.com" ||
        origin === "https://appuat.ezofis.com" ||
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "https://trial.ezofis.com"
      ) {
        linkText = `${origin}/portals/${this.$store.state.session.tenantId}/${portalId}`;
      } else {
        linkText = `${origin}/app/portals/${this.$store.state.session.tenantId}/${portalId}`;
      }
      await copyToClipboard(linkText);
      this.$alert.success(`Portal Access Link Copied`);
    },

    /* api functions */

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value,
            value: String(user.id),
          });
        });
      }
    },

    async getWorkflows() {
      this.workflowList = [];
      const { error, payload } = await workflow.getWorkflows({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "flowstatus",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 0,
        currentPage: 0,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching workflows");
        return;
      }

      if (payload) {
        if (payload.data.length) {
          payload.data[0].value.forEach((workflow) => {
            this.workflowList.push({
              id: this.$nano.id(),
              label: workflow.name,
              value: workflow.id,
            });
          });
        }
      }
    },

    async getPortals() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await portal.getPortalList({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;

      this.portals = data || [];
      this.portalData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async updatePortal(payload) {
      this.$store.commit("showLoadingBarPage");

      const { error } = await portal.updatePortal(
        this.selectedPortal.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const mode = payload.isDeleted ? "deleted" : "restored";
      this.$alert.success(
        `Portal "${this.selectedPortal.name}" ${mode} successfully`
      );

      this.getPortals();
    },

    searchRow(search) {
      if (search) {
        this.portals = this.portalData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.portals = this.portalData;
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#portal-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
